import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useAuth } from 'context/Auth';
import Home from './Pages/Home';
import { PrivateRoute } from 'helpers/PrivateRoute';
import { Header } from './Components/Header';
import PageAuth from './Pages/Auth';
import { AppWrapper, ErrorWrapper, LoadingScreen } from './styles';
import { Loader } from 'styles/common';
import { renderErrors } from 'app/Components/common/Error';
import { getTranslations } from 'config/translation';
import { TError, TFetchStatus } from 'models';
import { Button } from '@insly/qmt-reactjs-ui-lib';
import { logout } from 'utils';
import { useIntercom } from 'react-use-intercom';
import { INTERCOM_SHOULD_INITIALIZE } from './index';
import CustomerIDDList from './Pages/IDD/CustomerIDDList';
import QRBulkUpload from './Pages/common/QRBulkUpload';
import api from '../config/api';

const Routes = () => {
  const [networkStatus, updateNetworkStatus] = useState<TFetchStatus>(null);
  const [errors, updateErrors] = useState<TError[]>([]);

  let { isAuthenticated } = useAuth();

  const Quote = React.lazy(() => import('./Pages/Quote'));
  const Policy = React.lazy(() => import('./Pages/Policy'));
  const VehicleList = React.lazy(() => import('./Pages/Vehicle/List'));
  const Clients = React.lazy(() => import('./Pages/Clients'));
  const IDD = React.lazy(() => import('./Pages/IDD'));
  const Approve = React.lazy(() => import('./Pages/Public/Approve'));

  useEffect(() => {
    if (!networkStatus) {
      getTranslations({
        updateNetworkStatus,
        onError: updateErrors
      });
    }
  }, [networkStatus]);

  if (networkStatus !== 'success') {

    if (networkStatus === 'failed') {
      return (
        <LoadingScreen isAuthenticated={!!isAuthenticated}>
          <ErrorWrapper>
            {renderErrors(errors)}
            <Button onClick={logout}>Log Out</Button>
          </ErrorWrapper>
        </LoadingScreen>
      );
    }

    return (
      <LoadingScreen isAuthenticated={!!isAuthenticated}>
        <Loader />
      </LoadingScreen>
    );
  }

  return (
    <Router>
      <AppWrapper isAuthenticated={!!isAuthenticated}>
        <Header />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/auth/:section/:resetHash?">
              <PageAuth />
            </Route>
            <Route path="/public/:product/approve/:token?">
              <Approve />
            </Route>
            <PrivateRoute path="/quotes/:section/:type?/:quoteId?">
              <Quote />
            </PrivateRoute>
            <PrivateRoute path="/quotes/list">
              <Quote />
            </PrivateRoute>
            <PrivateRoute path="/policy/:section/:type?/:quoteId?">
              <Policy />
            </PrivateRoute>
            <PrivateRoute path="/vehicles">
              <VehicleList />
            </PrivateRoute>
            <PrivateRoute path="/clients/:section/:customerId?/:subsection?">
              <Clients />
            </PrivateRoute>
            <PrivateRoute path="/idd/:section/:iddId?/:subsection?">
              <IDD />
            </PrivateRoute>
            <PrivateRoute path="/gdpr/bulk_upload">
              <QRBulkUpload
                apiURL={`${api.GDPR}/signed`}
                goBackButtonTranslation="gdpr.bulk_upload.goBack"
                successTranslation="gdpr.bulk_upload.success"
                uploaderTranslation="gdpr.bulk_upload.uploader"
              />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/customer-idd-list/:customerId?">
              <CustomerIDDList />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Redirect to="/quotes/list" />
            </PrivateRoute>
          </Switch>
        </Suspense>
        <IntercomUpdater />
      </AppWrapper>
    </Router>
  );
};

// Hack to prevent whole app reload on Elastic list params changes
const IntercomUpdater = () => {
  const { user } = useAuth();
  const location = useLocation();

  const { update, shutdown, boot } = useIntercom();

  useEffect(() => {
    if (INTERCOM_SHOULD_INITIALIZE) {
      if (location?.pathname.startsWith('/public')) {
        shutdown();
      } else {
        boot();
      }

      update({
        name: user?.props.name,
        email: user?.props.email,
        userId: user?.broker ? `${user.broker.tag}_${user.props.email}` : user?.id,
        company: user?.broker ? {
          companyId: user.broker.tag,
          name: user.broker.name
        } : undefined
      });
    }
  }, [user?.id, location?.pathname]);

  return <div />;
};

export default Routes;
