import styled from 'styled-components';
import { Button, QuoteRow } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon, SMALL_SCREEN_WIDTH } from 'styles/common';

export const ControlsButton = styled(Button)<{ fullWidth?: boolean }>`
  height: 40px;
  min-width: 200px;
  &.qmt-ui-button--l {
    font-size: 14px;
    padding: ${({ fullWidth }) => fullWidth ? 0 : null};
    flex: ${({ fullWidth }) => fullWidth ? 1 : null};
    min-width: ${({ fullWidth }) => fullWidth ? 'auto' : null};
  }
`;

export const IssuingButton = styled(ControlsButton)`
  background: ${({ theme }) => theme.colors.primary};
  &.issuing-button {
    min-width: auto;
    padding: 10px 10px;
  }

  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    height: 30px;
    &.issuing-button {
      font-size: 12px;
      padding: 5px 5px;
    }
  }
`;

export const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  .qmt-ui-loader-animated {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
`;

export const ContentControlsRow = styled.div<{ paddingTop?: string }>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: 64px;
  gap: 16px;
`;

export const OffersContentControlsRow = styled(ContentControlsRow)`
  padding-bottom: 0;
`;

export const IconDefault = styled(DefaultIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  flex-shrink: 0;
`;

export const IconNextStep = styled(IconDefault)`
  stroke: ${({ theme }) => theme.colors.white};
  transform: rotate(180deg);
`;

export const IssuingIconNextStep = styled(IconNextStep)`
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    width: 16px;
    height: 16px;
  }
`;

export const QuoteRowWrapper = styled(QuoteRow)`
  grid-template-columns: minmax(60%, auto) 200px;
 
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
